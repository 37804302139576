*{
    caret-color: transparent;
}
.word-play{
    width: 100%;
    height: 100vh;
    background: url(../images/word-play/word_bg.jpg)center no-repeat;
    background-size:100% 100%;
    overflow: hidden;
    position: relative;
}
.word-play .route_bar{
    background: none;
    box-shadow: none;
}
.top-light{
    position: absolute;
    left: 0;
    top:-25px;
    width:100%;
    display: flex;
    justify-content: space-between;
    padding: 0 2%;
}
.top-light .light{
    display: block;
    width: 15vw;
    height:100vh;
    background: url(../images/word-play/big-light.png)left top no-repeat;
    background-size: 100%;
}
.top-light .light.right{
    transform: scaleX(-1);
}
.word-play .box{
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 11vh;
}
.center-light{
    position: absolute;
    left: 50%;
    top:-12.5vh;
    transform:translateX(-50%);
    width: 90%;
    z-index: 3;
    display:flex;
}
.center-light .light{
    width:calc(100% / 8);
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.billboard{
    max-width: 1312px;
    width: 70%;
    height: 71vh;
    position: relative;
    border-radius: 15px;
    box-shadow: 5px 5px 2px rgba(83, 28, 0, .32);
    padding:6px;
}
.billboard:before{
    content: '';
    width:100%;
    height:100%;
    background:linear-gradient(to bottom, #7178db, #91b3fe);
    opacity: .9;
    position: absolute;
    border-radius: 15px;
    left: 0;
    top: 0;
    display: block;
    z-index: 1;
}
.billboard-bar{
    position: absolute;
    width: 35px;
    height: 15vh;
    top: -11vh;
    background: linear-gradient(to bottom, #7178db, #91b3fe);
}
.billboard-bar.left{left:3%;}
.billboard-bar.right{right:3%;}
.billboard-wrap{
    position: relative;
    z-index: 2;
    width:calc(100% - 10px);
    height: calc(100% - 10px);
    margin:5px;
    border-radius: 7px;
    background:linear-gradient(to bottom, #7178db, #91b3fe);
    box-shadow: inset 0 2px 5px rgb(0 0 0 / 29%);
}
.billboard-line{
    width: 100%;
    height:100%;
    border: 5px solid transparent;
    border-radius: 15px;
    background-image:linear-gradient(to bottom, #919be1 0%, #91b3fd 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    position: absolute;
    left: 0;
    top:0;
    z-index: 1;
}
.word-contest{
    width: 35vw;
    height: 7vw;
    background: url(../images/word-play/word-contest.png)center no-repeat;
    background-size:100%;
    font-size:0;
    line-height:0;
    position: absolute;
    left: 50%;
    top:-3.8vw;
    transform: translateX(-50%);
}
.billboard-box{
    padding:4vw 50px 2vw 50px;
    height: 100%;
    position:relative;
    z-index:4;
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    display:flex;
    justify-content: center;
    align-items: center;
}
.billboard-box .inner{
    /*
    display:none;
    */
}
.word-select{
    width:100%;
}
.billboard-box .question{
    font-family: "NanumSquareRound", sans-serif;
    font-size: 2.3vw;
    color:#fff;
    font-weight: 900;
    margin-bottom:1.5vh;
}
.num-line{
    display:flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 5;
}
.num-line .word-box{
    margin-right: 20px;
    display: block;
    width: calc((100% - 60px) / 4);
    background: url(../images/word-play/box-bg.png)center no-repeat;
    background-size: 100% 100%;
    text-align: center;
    border-radius: 1.3vw;
    box-shadow: 5px 5px 0 rgba(0,0,0,.18);
    cursor: pointer;
    height: 5.5vw;
    transition:background .2s;
}
.wrong{
    background: #859be7 !important;
    border: 1px solid #00154d;
}
.num-line .word-box:nth-child(4n){
    margin-right: 0;
}
.num-line .word-box:nth-child(n+5){
    margin-top: 18px;
}
.num-line .word-box.on, .num-line .word-box:active{
    background: url(../images/word-play/box-bg-h.png)center no-repeat;
    background-size: 100% 100%;
    box-shadow: 5px 5px 0 rgba(255,255,255,.18);
}
.num-line .word-box.on span, .num-line .word-box:active span{
    color: #7b79ce;
}
.num-line .word-box.none{
    background: url(../images/word-play/box-bg-n2.png)center no-repeat;
    background-size: 100% 100%;
    box-shadow: 5px 5px 0 rgba(255,255,255,.18);
}
.num-line .word-box.none span{
    display:none !important;
}
.num-line .word-box .wrap{
    padding: 15px 10px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.num-line .word-box .num, .num-line .word-box .answer{
    font-family: "NanumSquareRound", sans-serif;
    font-size: 1.8vw;
    color: #fcb435;
    font-weight: 900;
    line-height:1;
    transition:color .2s;
    position: absolute;
    width: 100%;
}

.wrong_num_override{
    color: #fff !important;
}

.word_text_over{
    font-size: 1.7vw !important;
}

.score-line{
    position: absolute;
    left: 0;
    bottom:0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding:0 2% 1% 2%;
}
.score-box{
    position: relative;
    z-index: 8;
}
.score-box .score-table{
    width: 13.5vw;
    height: 21vw;
    display: block;
    position: relative;
}
.score-box.left .score-table{
    background: url(../images/word-play/score_character.png)center bottom no-repeat;
    background-size: 100%;
}
.score-box.right .score-table{
    background: url(../images/word-play/score_user.png)center bottom no-repeat;
    background-size: 100%;
}
.character{
    position:absolute;
    bottom: 10.25vw;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.character{
    bottom:13.5vw;
}
.character span{
    width: 87%;
}
.character span img{
    width:100%;
}
.particle{
    position: absolute;
    left:50%;
    top:-5vw;
    z-index: 3;
    opacity: 0;
    animation:pow .5s .5s;
    animation-fill-mode: forwards;
    transform: translateX(-50%);
    width:110%;
}
.particle img{
    width:100%;
    max-width:400px;
}
@keyframes pow {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
.character .face{
    margin-bottom: -7%;
    position: relative;
    z-index: 2;
    box-shadow: 7px 7px 8px rgba(83, 28, 0, .23);
    width: 71%;
    height: 71%;
    overflow:hidden;
    border-radius: 50%;
}
.character .face img{
    width:100%;
}
.character .body{
    width: 85%;
}
.character .body img{
    width:100%;
}
.character .body{
    overflow: hidden;
}
.character-name{
    font-family: "NanumSquareRound", sans-serif;
    font-size: 2vw;
    color: #000;
    font-weight: 800;
    text-align: center;
    display: block;
    position: relative;
    top: 3.2vw;
    width: 67%;
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.score-num{
    font-family: "NanumSquareRound", sans-serif;
    font-size: 2.1vw;
    font-weight: 800;
    text-align: center;
    display: block;
    position: relative;
    top: 5.3vw;
}
.score-box.left .score-num{
    color: #2af749;
}
.score-box.right .score-num{
    color: #fff87c;
}
.score-table .btm{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    bottom: 3.8vw;
    width: 100%;
    height:5.6vw;
}
.bootharea{
    position: absolute;
    width: 55%;
    height: 100%;
    z-index: 1;
}
.bootharea img{
    width: 100%;
    position: absolute;
    left:0;
    top:0;
}
.score-table .btm .answer-word{
    font-family: "NanumSquareRound", sans-serif;
    font-size:2vw;
    color:#fff;
    font-weight: 700;
    z-index: 2;
}
.score-table .btm .count{
    width: 4vw;
    height: 4vw;
    position: absolute;
    z-index: 2;
}
.score-table .btm .count img{
    width:70%;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
}
.score-table .btm .thropy{
    width: 25%;
    margin: 2% 0 0 2%;
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    z-index: 2;
}
.score-table .btm .thropy img{
    width:100%;
}
.answer-result{
    position: absolute;
    z-index: 5;
    top: -5vh;
    display:flex;
    justify-content: center;
}
.score-box.right .answer-result{
    top: -7vh;
}
.answer-result img{
    display: block;
}
.correct-img{
    max-width: 300px;
    width:90%;
}
.oops-img{
    max-width:279px;
    width:80%;
}
.quiz-layer{
    background: #fff;
    padding: 25px;
    border-radius: 25px;
    box-shadow: 4px 4px 8px rgba(0, 39, 66, .4);
    margin-bottom: 2vh;
    max-width: 800px;
    width: 37vw;
    max-height: 42vh;
    position:relative;
}
.quiz-layer .quiz-pic{
    width:100%;
}

.quiz-layer .sound-icon{
    width: 6%;
    cursor:pointer;
    position: absolute;
    bottom:0;
    right:0;
    margin:20px;
}
.quiz-word{
    display:flex;
    justify-content: space-between;
    padding:0 18px;
}
.quiz-word .word-pick{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "NanumSquareRound", sans-serif;
    font-size:2.5vw;
    font-weight: 900;
    text-align: center;
    background-color: #fff;
    border:5px solid #fcb333;
    border-radius: 15px;
    box-shadow: 4px 4px 8px rgba(0, 39, 66, .4);
    width: 14vw;
    padding: 0.5vh 10px;
    line-height: 2.75vw;
    cursor: pointer;
}

.bigger-word-pick{
    width: 17vw !important;
}

.quiz-word .word-pick:active,.word-pick.answered, .quiz-word .word-pick:active{
    background-color: #fcb333;
    outline:none;
}
.result_word .modal_overlay{
    z-index: 8;
}
.result_word .modal_wrapper {
    flex-wrap: wrap;
    transform: translate(-50%, -50%);
}

.result_word .top .winner{
    font-family: "NanumSquareRound", sans-serif;
    text-align: center;
    font-size:2.5vw;
    font-weight: 900;
    position: relative;
    padding-top: 21%;
    margin-top: -1.5vw;
}
.result_word .top .winner:before{
    content:'';
    width: 100%;
    height:100%;
    background: url(../images/word-play/winner-is.png)center top no-repeat;
    background-size:100%;
   position: absolute;
   left:0;
   top:0;
}

.result_word .buttongroup {
    width:100%;

}
.result_word .buttongroup.img-btn span{
    margin:10px 15px 10px 15px !important;
    width:100px;
    height:100px;
}

/*hht2 word-play*/
.word-play2{
    overflow:hidden;
    width: 100%;
    height: 100vh;
}

.left-btn{
    position:absolute;
    top:50%;
    left:25px;
}

.right-btn{
    position:absolute;
    top:50%;
    right:25px;
}

.word-play2 .route_bar{
    background: none;
    box-shadow: none;
    padding-right: 0;
}
.word-play2 .titleW{
    font-family: "NanumSquareRound", sans-serif;
    font-size: 2.3vw;
    color: #b0d14a;
    font-weight: 800;
    position: absolute;
}
.word-play2 .titleW br{
    display:none;
}
.polaroid-wrap{
    padding: 1% 2%;
    padding-right:36%;
    height:100%;
    background: url(../images/word-play/type2-word-bg.jpg)left top no-repeat;
    background-size: 100% 100%;
}
.polaroid-wrap .inner{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width:100%;
    height: 100%;
    padding-top: 3.8vw;
}
.word-piece-wrap{
    width: calc(100% - 28vw);
    height:100%;
    padding:5vh 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.word-piece{
    width: 100%;
    max-height: 100%;
    padding:0 1%;
}
.word-piece div{
    height: 25%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10%;
}
.word-piece span{
    font-family: "NanumSquareRound", sans-serif;
    font-size: 3.2vw;
    font-weight: 800;
    display: inline-block;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: center;
    padding: 2% 18%;
    cursor: pointer;
}
.word-piece .green{background-image: url(../images/word-play/text_box_01.png); width:100%; }
.word-piece .pink{background-image: url(../images/word-play/text_box_02.png); width:100%; }
.word-piece .blue{background-image: url(../images/word-play/text_box_03.png); width:100%; }
.word-piece .beige{background-image: url(../images/word-play/text_box_04.png); width:100%; }
.word-piece span:active{
    color: #cc003d;
}
.word-piece .over_text{
    padding: 2% 6% !important;
}

.word-piece .check span{
    color: #cc003d;
    position: relative;
}
.word-piece .check span:after{
    content: '';
    width:3vw;
    height: 80%;
    background: url(../images/word-play/check.png)left center no-repeat;
    background-size:100%;
    position: absolute;
    right:-0.5vw;
    top:0;
}
.polaroid {
    position: relative;
    top: 30%;
    transform: translateY(-50%);
}
.polaroid .camera{
    width:28vw;
    height:25.5vw;
    background:url(../images/word-play/polaroid_camera.png)left top no-repeat;
    background-size: 100%;
    position: relative;
}
.polaroid .camera .count{
    font-family: "NanumSquareRound", sans-serif;
    font-size:2vw;
    font-weight: 900;
    position: absolute;
    left: 48%;
    transform: translateX(-50%);
    top: 0;
    display: flex;
    align-items: center;
}
.polaroid .camera .count .point{
    font-family: "NanumSquareRound", sans-serif;
    font-style: normal;
    font-weight: 900;
    color: #c1392a;
}
.polaroid .camera .count .slash{
    font-family: "NanumSquareRound", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 1vw;
    margin: 0 0.5vw;
}
.polaroid .camera .bottom{
    width:78%;
    position: absolute;
    left: 50%;
    top:88%;
    transform: translateX(-50%);
    padding:0 10px;
    padding-top: 10px;
    overflow: hidden;
    background:url(../images/word-play/polaroid_camera-bg.png)left top no-repeat;
    background-size: 100%;
}
.polaroid .camera .photo-wrap{
    overflow: hidden;
}
.polaroid .camera .photo{
    width: 100%;
    background:url(../images/word-play/polaroid_photo.png)left top no-repeat;
    position: relative;
    top:-18vw;
    padding: 0.8vw 1.2vw 1vw 1vw;
    background-size: 100% 100%;
}
.polaroid .camera .photo.pick{
    animation:photo-animation ease 0.8s 1.2s;
    animation-fill-mode: forwards;
}
@keyframes photo-animation {
    0% {
      top:-18vw;
    }
    100% {
      top:0;
    }
  }
.polaroid .camera .photo .imgarea{
    width:100%;
    margin-bottom: 15px;
}
.polaroid .camera .photo .imgarea img{
    width: 100%;
}
.polaroid .camera .photo .word{
    display:flex;
    height: 18%;
    align-items: center;
}
.polaroid .camera .photo .word img{
    cursor: pointer;
    padding-right:10px;
    width: 15%;
}
.polaroid .camera .photo .word .p{
    width:85%;
    height: 6vh;
    position: relative;
    max-height: 80px;
}
.polaroid .camera .photo .word .p img{
    position: absolute;
    left: 0;
    top:0;
    padding:0 !important;
    width: 100%;
    height: 100%;
}
.polaroid .camera .photo .word .p p{
    font-family: "NanumSquareRound", sans-serif;
    position: absolute;
    font-size:1.9vw;
    font-weight: 900;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
}
.photo-album{
    background: #add454;
    width:35%;
    position: absolute;
    right:0;
    top:0;
    height: 100vh;
    padding: 3vh 1.5vw;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    overflow: hidden;
}
.photo-album .titleW{
    font-size:0 !important;
    line-height:0;
    /*background: url(../images/word-play/photo-album-title.png)left top no-repeat;*/
    width: 19vw;
    height: 8.3vw;
    margin: 0 auto;
    background-size: 100%;
}

.photo-album .titleS{
    position: absolute;
    top:80px;
    font-family: 'ONE-Mobile-POP';
    font-size: 2vw;
    display: inline-block;
    background:#fff;
    position: relative;
    padding: 1vw 4vw;
    box-shadow: 0 0 10px rgb(0 0 0 / 30%);
    color:#00b3b3;
    height: 5vw;
    margin: 0 auto;
    background-size: 100%;
}
.photo-album .titleS::after{
    content: '';
    width: 20px;
    height: 24px;
    position: absolute;
    left: 50%;
    top: -11px;
    transform: translateX(-50%);
}

.photo-album .titleS::before{
    content: '';
    width: 90%;
    height: 80%;
    position: absolute;
    left: 5%;
    top:10%;
    border:2px dashed #e2e2e2;
}

/*.photo-album .titleS{color: #6a79d8;transform: rotate(-3deg);}*/
.photo-album .titleS:after{background: url(../images/word-play/pin-blue.png)left top no-repeat;background-size: 100%;}


.photo-album .btnarea{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 1vh;
}
.photo-album .btnarea .arrow{
    width: 1.5vw;
    height: 2.5vw;
    background: url(../images/word-play/photo-album-arrow.png)left top no-repeat;
    background-size:100%;
    cursor: pointer;
}
.photo-album .btnarea .arrow.next{
    transform: rotate(180deg);
}
.photo-album .btnarea .arrowactive{/* JHS  */
    width: 1.5vw;
    height: 2.5vw;
    background: url(../images/word-play/photo-album-arrow.png)left top no-repeat;
    background-size:100%;
    cursor: pointer;
}
.photo-album .btnarea .arrowactive.next{/* JHS  */
    transform: rotate(180deg);
}
.photo-album .btnarea .complet-btn{
    font-family: "NanumSquareRound", sans-serif;
    font-size:1.8vw;
    font-weight: 900;
    background: #10aec6;
    color:#fff;
    padding: 0.5vw 2vw;
    border-radius: 20vw;
    cursor: pointer;
    margin: 0 3vw;
    font-style: normal;
}
.photo-album .btnarea .complet-btn2{
    font-family: "NanumSquareRound", sans-serif;
    font-size:1.8vw;
    font-weight: 900;
    background: #e6e6e6;
    color:#fff;
    padding: 0.5vw 2vw;
    border-radius: 20vw;
    cursor: pointer;
    margin: 0 3vw;
    font-style: normal;
}
.photo-zone{
    padding: 4vh 1vw;
    width: 100%;
    height: calc(100% - 8.3vw);
    display: none;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top:8.3vw;
}
.photo-zone.now{
    display: flex;
}
.classification .photo-zone{
    display:flex;
    position: relative;
    left: auto;
    top: auto;
}
.photo-zone-inner{
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    min-height: 37vh;
}
.photo-zone .photo{
    width: 29%;
    background: url(../images/word-play/polaroid_photo_s.png)left top no-repeat;
    background-size: 100% 100%;
    padding:0.5vw 1vw 0.6vw 0.5vw;
    margin: 0 2%;
    height: 50%;
}
.photo-zone .photo:nth-child(n+3){
    margin-top: 2vh;
}
.photo-zone .photo .imgarea{
    width: 100%;
}
.photo-zone .photo .imgarea img{
    width: 100%;
    display: block;
}
.photo-zone .photo p{
    font-family: "NanumSquareRound", sans-serif;
    font-size:1vw;
    font-weight: 900;
    text-align: center;
}

.photo-zone .photo .over_text{
    font-size:0.83vw !important;
}



.classification{
    padding: 1% 2%;
    height:100%;
    background: url(../images/word-play/type2-word-bg2.jpg)left top no-repeat;
    background-size: 100% 100%;
}
.classification .titleW{
    color: #0fa13f;
}
.board{
    background: url(../images/word-play/frame-board.png)left top no-repeat;
    width: 100%;
    height: calc(100% - 4vw);
    background-size: 100% 100%;
    box-shadow: 0 5px 10px rgba(0,0,0,.3);
    margin-top: 3.8vw;
    padding: 3vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.board .left, .board .right{
    background:rgba(0,0,0,.3);
    height: 100%;
    width:30%;
    text-align: center;
}
.board .titleP{
    font-family: 'ONE-Mobile-POP';
    font-size: 1.8vw;
    display: inline-block;
    background:#fff;
    position: relative;
    padding: 0.5vw 4vw;
    text-shadow: 2px 2px 1px rgba(0,0,0,.2);
    margin-top: -11px;
    box-shadow: 0 0 10px rgb(0 0 0 / 30%);
}
.board .titleP:after{
    content: '';
    width: 20px;
    height: 24px;    
    position: absolute;
    left: 50%;
    top:-12px;
    transform: translateX(-50%);
}
.board .titleP::before{
    content: '';
    width: 90%;
    height: 80%;
    position: absolute;
    left: 5%;
    top:10%;
    border:2px dashed #e2e2e2;
}


.board .left .titleP{color: #6a79d8;transform: rotate(-3deg);}
.board .left .titleP:after{background: url(../images/word-play/pin-blue.png)left top no-repeat;background-size: 100%;}
.board .right .titleP{color: #009562;transform: rotate(3deg);}
.board .right .titleP:after{background: url(../images/word-play/pin-green.png)left top no-repeat;background-size: 100%;}
.board .photo-zone{
    padding: 2vh 1.5vw;
    height: calc(100% - 3.5vw);
}
.board .photo-zone .photo:nth-child(n+3){
    margin-top: 0;
}
.board .photo-zone .photo{
    width: 40%;
    margin: 0 5%;
    padding: 0.5vw 1vw 0.5vw 0.5vw;
    cursor: pointer;
    height: 25%;
}
.board .photo-zone .photo p{
    font-size: 1.1vw;
}
.board .center{
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.board .photo-zone-inner{
    min-height: 90%;
    max-height: 100%;
}
.photos{
    position: relative;
    width: 70%;
    height: 22vw;
}
.photos .photo{
    display:flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    background: url(../images/word-play/polaroid_photo_m.png)left top no-repeat;
    background-size: 100% 100%;
    padding: 1.5vw 2vw;
    margin: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.photos .photo .imgarea{
    width: 90%;
    margin:0 auto;
}
.photos .photo .imgarea img{
    width: 100%;
}


.photos .photo .leftBtn{
    display:flex;
    justify-content: flex-end;
    width: 4vw;
    position:absolute;
    top:37%;
    left:-5vw;
}
.photos .photo .leftBtn:hover{
    filter: brightness(0.9);
}


.photos .photo .rightBtn{
    width: 4vw;
    position:absolute;
    top:37%;
    right:-5vw;
}

.photos .photo .rightBtn:hover{
    filter: brightness(0.9);
}

.photos .photo p{
    font-family: "NanumSquareRound", sans-serif;
    font-size: 3.1vw;
    font-weight: 900;
    text-align: center;
}

.photos .photo .over_text{
    font-size: 2.2vw !important;
    padding: 7px;

}

.word-piece .green{transform:rotate(-7deg);}
.word-piece .pink{transform:rotate(2deg);}
.word-piece .blue{transform:rotate(-1deg); }
.word-piece .beige{transform:rotate(-10deg);}

.guide-left{
    position: fixed;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    z-index: 15;    
    animation:guide-left 2s 3; /*3번 반복 총6초 후에 사라지게 해야함*/
    animation-fill-mode: forwards;
}
.guide-left img{
    width: 8vw;
    height: 11vw;
}

@keyframes guide-left {
    0% {
        left: 50%;
        top: 60%;
    }
    100% {
        left: 24%;
        top: 43%;
    }
}

.guide-right{
    position: fixed;
    right: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    z-index: 15;
    animation:guide-right 2s 3; /*3번 반복 총6초 후에 사라지게 해야함*/
    animation-fill-mode: forwards;
}

.guide-right img{
    width: 8vw;
    height: 11vw;
}

@keyframes guide-right {
    0% {
        right: 40%;
        top: 60%;
    }
    100% {
        right: 15%;
        top: 43%;
    }
}

.click-guide{
    top: -40px;
    position: relative;
    z-index: 15;
    animation:click-guide 0.5s 5; /*3번 반복 총6초 후에 사라지게 해야함*/
    animation-fill-mode: forwards;
}

.click-guide img{
    width: 8vw;
    height: 11vw;
}

@keyframes click-guide {
    0% {
       transform: rotate3d(0, 0, 0, 0);
    }
    100% {
        transform:  rotate3d(0.2,0,0,30deg);
    }
}

@media (min-width: 2000px){
    .billboard{
        max-width: inherit;
    }
    .billboard-box{
        max-width: inherit;
        width: 92%;
    }
    .quiz-layer{
        max-width: inherit;
        width: 50vw;
    }
    .photo-album .btnarea .complet-btn{
        font-size: 2.3vw;
    }
    .photo-album .btnarea .complet-btn2{
        font-size: 2.3vw;
    }
    .board .photo-zone{
        padding: 2vh 1vw;
    }
    .board .photo-zone .photo {
        width: 46%;
        margin: 0 2%;
    }
    .board .photo-zone .photo p {
        font-size: 1.5vw;
    }
    .board .photo-zone-inner{
        width: 100%;
    }
    .result_word .modal_wrapper{
        width: 50vw;
    }
    .polaroid .camera {
        width: 27vw;
        height: 24.8vw;
    }
}
@media (min-width: 1500px){
    .polaroid .camera {
        width: 24vw;
        height: 22.5vw;
    }
    .polaroid .camera .bottom{
        padding-top:7px;
    }
}
@media (max-width: 1388px){
    .center-light{
        top: -12vh;
    }
    .center-light .light {
        width: calc(100% / 6);
    }
    .center-light .light:nth-child(n+7){display:none;}
    .center-light .light img{
        width: 120%;
    }
    .billboard-bar{
        width:25px;
    }
    .word-contest{
        width: 40vw;
        height: 8.2vw;
        top: -4.1vw;
    }
    .score-box .score-table {
        width: 17.6vw;
        height: 26vw;
    }
    .character{
        bottom:12.2vw;
    }
    .left .character {
        bottom: 15.5vw;
    }
    .character-name{
        font-size: 2.4vw;
        top: 3vw;
    }
    .score-num{
        font-size: 2.8vw;
        top: 5.6vw;
    }
    .score-table .btm{
        bottom: 5.2vw;
        height: 6vw;
    }
    .num-line .word-box{
        margin-right: 15px;
        width: calc((100% - 46px) / 4);
        height: 6.2vw;
    }
    .num-line .word-box:nth-child(n+5) {
        margin-top: 13px;
    }
    .num-line .word-box .num, .num-line .word-box .answer{
        font-size: 2.2vw;
    }
    /*.result_word .buttongroup.img-btn span{*/
    /*    width:60px;*/
    /*    height:60px;*/
    /*}*/
    .quiz-layer{
        max-height: inherit;
        height: 39vh;
        display:flex;
        justify-content: center;
        align-items: center;
        width: 45vw;
    }
    .quiz-layer quiz-pic{
        width: auto;
        max-height: 100%;
        display: block;
        max-width: 100%;
    }
    .quiz-word .word-pick{
        font-size: 2.8vw;
        line-height: 5vw;
    }
    .billboard-box .question{
        font-size: 3vw;
        margin-bottom: 2vh;
    }
    .word-piece {
        width: 100%;
    }
    .word-piece span{
        font-size: 3.2vw;
        padding: 10px 44px;
        max-width: 100%;
        min-width: auto;
    }
    .word-piece .check span:after{
        right: -4%;
    }
    .polaroid .camera .count{
        font-size: 2vw;
    }
    .polaroid .camera {
        width: 24vw;
        height: 22vw;
    }
    .polaroid .camera .photo .imgarea{
        margin-bottom: 5px;
    }
    .polaroid .camera .bottom{
        width: 86%;
        padding: 0 2px 0 9px;
        padding-top: 6px;
    }
    .polaroid .camera .photo .word img{
        width:14%;
    }
    .polaroid .camera .photo.pick{
        top: -25vw;
    }
    .photo-album .titleW{
        width: 18vw;
        height: 8.3vw;
    }
    .photo-zone{
        top: 10.5vw;
        height: calc(100% - 10.3vw);
    }
    .photo-zone .photo{
        background-size: 100% 100%;
    }
    .photo-zone .photo p{
        font-size: 1.2vw !important;
    }
    .photo-album .btnarea .complet-btn{
        font-size: 2vw;
        padding: 1vw 2vw;
    }
    .photo-album .btnarea .complet-btn2{
        font-size: 2vw;
        padding: 1vw 2vw;
    }
    @keyframes photo-animation {
        0% {
        top:-25vw;
        }
        100% {
        top:0;
        }
    }
    .board{
        padding: 2.5vw 2.5vw 2vw 2.5vw;
        margin-top:4.2vw;
        height: calc(100% - 4.5vw);
    }
    .board .titleP:after {
        width: 14px;
        height: 19px;
        top: -8px;
    }
    .board .photo-zone{
        padding: 2vh 1vw;
    }
    .board .photo-zone .photo {
        width: 41.9%;
        margin: 0 4%;
    }
    .board .photo-zone .photo p{
        font-size: 1.5vw;
    }
    .board .photo-zone-inner{
        width: 100%;
    }
}
@media (max-width: 1280px){
    .word-play2 .titleW{
        font-size: 2.2vw;
        line-height: 1.4;
        max-width: 60vw;
    }
}
@media (max-height: 768px) {
    .board .photo-zone .photo p {
        font-size: 1vw;
    }
}


@media(max-width:1254px),(max-height:652px){
    .result_word .top .winner_img{
        width: 30vw;
    }
    .result_word .buttongroup.img-btn span{
        margin:0 12px 0 12px !important;
        width:60px;
        height:60px;
    }
    .photos{
        width: 80% !important;
        height: 23vw !important;
    }
    .photo-zone .photo p{
        font-size: 1vw !important;
    }
    .photo-album .titleS{
        top: 40px !important;
    }

    .board .photo-zone .photo{
        width: 35% !important;
        margin: 0 7% !important;
    }
    .photo-zone-inner{
        width: 90% !important;
    }


}


@media (max-width: 1024px), (max-height:768px) {
    .billboard-box {
        padding: 4vw 3vw 2vw 3vw;
    }
    .billboard-box .question {
        font-size: 2.5vw;
        margin-bottom: 3vh;
    }
    .score-box .score-table {
        width: 18.6vw;
        height: 27.4vw;
    }
    .character{
        bottom:12.2vw;
    }
    .left .character {
        bottom: 15.5vw;
    }
    .character-name{
        font-size: 2.7vw;
        top: 3.1vw;
    }
    .score-table .btm .answer-word{
        font-size: 2.3vw;
    }
    .score-num{
        font-size: 3vw;
        top: 5.6vw;
    }
    .quiz-word .word-pick{
        font-size: 2.8vw;
        line-height: 3.7vw;
        width: 16vw;
        padding: 1vh 10px;
    }
    .result_word .top .winner_img{
        width: 30vw;
    }
    .result_word .buttongroup.img-btn span{
        margin:0 12px 0 12px !important;
        width:60px;
        height:60px;
    }
    .result_word .modal_wrapper{
        padding: 40px 60px 35px 60px;
    }
    .polaroid-wrap .inner{
        padding-top: 4.5vw;
    }
    .polaroid .camera {
        width: 28vw;
        height: 26vw;
    }
    .polaroid .camera .count {
        font-size: 2.2vw;
    }
    .photo-zone .photo .imgarea{
        margin-bottom: 1vh;
    }
    .classification .photo-zone .photo .imgarea{
        margin-bottom: 0;
    }
    .polaroid .camera .photo{
        padding: 10px 15px 10px 11px;
    }
    .photo-album{
        padding: 3vw 1.5vw 2vh;
    }
    .polaroid .camera .photo .word .p{
        height: 5.5vh;
    }
    .board{
        padding: 3vw 2.5vw 2vw 2.5vw;
    }
    .board .left, .board .right{
        width: 35%;
    }
    .board .titleP{
        margin-top: 3px;
    }
    .board .photo-zone .photo {
        width: 45.9%;
        margin: 0 2%;
    }
    .board .photo-zone .photo p {
        font-size: 1.6vw;
    }
    .photos {
        width: 80%;
        height: 23vw;
    }
    .photos .photo p{
        font-size: 3.5vw;
    }
    .board .photo-zone{
        height: calc(100% - 4.5vw);
    }    
    .word-play2 .titleW br{
        display:none;
    }
    .board .photo-zone-inner {
        width: 94%;
    }
}
/*태블릿 7인치 이하*/
@media (max-height: 650px) {
    .billboard-box {
        padding: 4vw 3vw 1vw 3vw;
    }
    .result_word .modal_wrapper{
        width: 42vw;
        padding: 30px 40px 25px 40px;
    }
    .result_word .buttongroup{
        /*padding-top: 21px;*/
        /*margin-top: 11px;*/
    }

    .result_word .buttongroup.img-btn span{
        width:40px;
        height:40px;
    }
    .score-box .score-table {
        width: 17.6vw;
        height: 26vw;
    }
    .score-num {
        font-size: 2.5vw;
        top: 6.2vw;
    }
    .character-name {
        font-size: 2.3vw;
        top: 3.2vw;
    }
    .character {
        bottom: 12.2vw;
    }
    .left .character {
        bottom: 15.5vw;
    }
    .num-line .word-box{
        box-shadow: 3px 3px 0 rgb(0 0 0 / 18%);
    }
    .num-line .word-box.on, .num-line .word-box:active{
        box-shadow: 3px 3px 0 rgb(255 255 255 / 18%);
    }
    .num-line .word-box:nth-child(n+5) {
        margin-top: 9px;
    }
    .quiz-layer{
        height: 34vh;
    }

    .quiz-word .word-pick {
        font-size: 2.6vw;
        line-height: 4.5vw;
        border-width: 3px;
        padding: 0.5vh 10px;
    }
    .score-table .btm .thropy {
        width: 21%;
    }
    .photo-album {
        padding: 5vh 1.5vw 3vh 1.5vw;
    }
    .word-play2 .titleW{
        font-size: 2.5vw;
        max-width: 60vw;
    }
    .polaroid-wrap .inner {
        padding-top: 13vh;
    }
    .polaroid{
        top: 0;
        transform: translateY(0);
    }
    .polaroid .camera {
        width: 50vh;
        height: 46vh;
    }
    .polaroid .camera .photo{
        background-size: 100% 100%;
    }
    .polaroid .camera .photo .word .p p{
        font-size: 2vw;
    }
    .photo-album .titleW {
        width: 16vw;
        height: 7.3vw;
    }
    .photo-zone .photo{
        padding:4px 9px 8px 5px;
    }
    .photo-zone .photo p {
        font-size: 3.2vh;
    }
    .photo-zone-inner {
        width: 49vh;
    }
    .word-piece span{
        font-size:5vh;
    }
    .board .titleP{
        font-size: 2.3vw;
        margin-top:0;
    }
    .board {
        padding: 2.5vw 2vw 2vw 2vw;
    }
    .board .photo-zone {
        padding:2vh 0;
    }
    .board .photo-zone .photo{
        padding: 6px 8px 4px 5px;
    }
    .board .photo-zone .photo p {
        font-size: 1.5vw;
    }

    .photos{
        width: 70% !important;
        height: 20vw !important;
    }
    .photos .photo{
        padding: 1vw 1.5vw;
    }
    .photos .photo p{
        font-size: 3.2vw;
    }
    .board .photo-zone .photo p {
        font-size: 1.3vw;
    }
    .board .photo-zone-inner {
        width: 78%;
    }    
    .num-line .word-box .wrap{
        padding: 15px 10px 10px 10px;
    }


}